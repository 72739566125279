import { useMsal } from "@azure/msal-react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
 import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";

import { postAddUser } from "utils/ds.js";


import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { getAppList, getLogs, getSettings, postDelUser, postSettings, getAdminUsers } from "utils/ds.js";
import { callMsGraph } from "utils/MsGraphApiCall";

import asset_logo_thive from "assets/img/asset_logo_thive.png";
import validator from 'validator';




const MainBox = styled(Box)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'right',
    height: "100%",
    width: "100%",
    background: "#092755",
    alignItems: "center",

}));

const MainContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    overflow: "hidden",
    width: "100%",
    maxWidth: "1400px",
    justifyContent: "center",
    marginRight: "10px",
    alignContent: "flex-start"
}));


const BoxTopBorder = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    height: "10px",

    background: "#F2A92C",
    width: "100%"
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",

    display: "flex",
    height: "100px",
    background: "white",
    width: "100%"
}));

const AppsContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "100%",
    alignContent: "center",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    marginTop: "20px"
}));

const AppPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "100px",
    maxWidth: "1000px",
    width: "80%",
    justifyContent: "center"
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "100%",
    width: "100%",
}));

const UnsubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "90%",
    width: "100%",

}));

const EmailBox = styled(TextField)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "30px",
    width: "100%"
}));



function ModLogs(props) {
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [loginValue, setLoginValue] = useState("");

    const [adminList, setAdminList] = useState([]);
    const [logs, setLogs] = useState([]);
    const [inputValid, setInputValid] = useState("");
    const [appsData, setAppsData] = useState([]);

    const [activationData, setActivationData] = useState({});

    useEffect(() => {
        updateData();
    }, []);


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'dateInserted', headerName: 'Date', width: 200 },
        { field: 'code', headerName: 'Type', width: 120 },
        { field: 'message', headerName: 'Message', width: 500 },
        { field: 'user', headerName: 'UPN', width: 200 },
        { field: 'ipAddress', headerName: 'IP address', width: 200 },
    ];

    const updateData = () => {
        getLogs().then(response => {
            setLogs(response)

        });
    }



    return (
        <MainBox id={"box_mainBox"}>
            <BoxTopBorder id={"box_topborder"} />
            <BoxTHiveLogo id={"box_thive_logo"}>
                <img src={asset_logo_thive} alt="T-HIVE Logo" />
            </BoxTHiveLogo>
            <MainContainer id={"box_main_container"} container spacing={1} sx={{ marginTop: "24px", height: "70%", alignContent: "center", background: '#f3f3f3' }} >
                <Grid item xs={12} sx={{ height: "80%", paddingBot: "10px" }}>
                    <DataGrid
                         rows={logs}
                         columns={columns}
                         components={{ Toolbar: GridToolbar }}
                         density='compact'
                    />
                </Grid>
            </MainContainer>
        </MainBox>
    );
}

export default ModLogs;