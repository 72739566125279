import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import rdx from 'redux/rdx'


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
    commons:rdx,
});

const configureStore = (initialState) => createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));
export default configureStore;