import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import _ from "lodash";

import { loginRequest } from "authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { callMsGraph } from "utils/MsGraphApiCall";
import {
  getAppList,
  getMembership,
  askMembership,
  reovkeMembership,
  postSettings,
  getSettings,
} from "utils/ds.js";

import asset_logo_thive from "assets/img/asset_logo_thive.png";

import axios from "axios";

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "right",
  height: "100%",
  width: "100%",
  background: "#092755",
  alignItems: "center",
  overflow: "hidden" /* Hide scrollbars */,
}));

const BoxTopBorder = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  height: "10px",

  background: "#F2A92C",
  width: "100%",
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",

  display: "flex",
  height: "100px",
  background: "white",
  width: "100%",
}));

const MainContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  // height: "30px",
  width: "100%",
  maxWidth: "1400px",
  justifyContent: "center",
  overflow: "auto",
  marginRight: "10px",
}));

const CardContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  width: "100%",
  justifyContent: "left",
}));

const AppsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  height: "100%",
  overflow: "hidden" /* Hide scrollbars */,
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  overflow: "auto",
  marginTop: "20px",
}));

const AppPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  height: "100px",
  maxWidth: "1000px",
  width: "80%",
  justifyContent: "center",
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
  marginTop: "8px",
  height: "100%",
  width: "100%",
}));

const UnsubscribeButton = styled(Button)(({ theme }) => ({
  marginTop: "8px",
  height: "90%",
  width: "100%",
}));

function ModAdminApp(props) {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [appsData, setAppsData] = useState([]);

  const [activationData, setActivationData] = useState({});

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  useEffect(() => {}, []);

  const updateData = () => {
    if (graphData !== null) {
      getAppList()
        .then((response) => {
          setAppsData(response);

          getSettings()
            .then((e) => {
              setActivationData(JSON.parse(e.config));
            })
            .catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect({
                  ...loginRequest,
                  account: instance.getActiveAccount(),
                });
              }
            });
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  };

  useEffect(() => {
    updateData();
  }, [graphData]);

  const updateDbSettings = () => {
    postSettings(activationData);
  };

  const isActivated = (appId) => {
    return !(activationData[appId] === undefined);
  };

  const handleActivation = (appId, appName) => {
    let copyData = activationData;
    if (copyData[appId] === undefined) {
      copyData[appId] = { id: appId, name: appName };
      setActivationData({ ...copyData });
      updateDbSettings();
    } else {
      delete copyData[appId];
      setActivationData({ ...copyData });

      updateDbSettings();
    }
  };

  return (
    <MainBox id={"box_mainBox"}>
      <BoxTopBorder id={"box_topborder"} />
      <BoxTHiveLogo id={"box_thive_logo"}>
        <img src={asset_logo_thive} alt="T-HIVE Logo" />
      </BoxTHiveLogo>

      <MainContainer
        id={"box_main_container"}
        container
        spacing={1}
        sx={{
          marginTop: "24px",
          height: "70%",
          alignContent: "center",
          background: "#f3f3f3",
        }}
      >
        <Grid item xs={12} sx={{ height: "80%", paddingBot: "10px" }}>
          <UnauthenticatedTemplate>
            <Typography variant="h6">
              <center>Not Logged In</center>
            </Typography>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Typography variant="titles">
              <center>Applications activation</center>
            </Typography>
            <AppsContainer
              container
              direction="row"
              id={"app_container"}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {appsData !== undefined &&
                appsData.map((e) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AppPaper e={e}>
                        <CardContainer
                          container
                          spacing={1}
                          sx={{ height: "100%", alignContent: "center" }}
                        >
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              height: "100%",
                              paddingTop: "20px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                paddingLeft: "10px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {e.displayName || "undefined"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              height: "100%",
                              paddingTop: "20px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isActivated(e.appId)}
                                    onClick={() =>
                                      handleActivation(e.appId, e.displayName)
                                    }
                                  />
                                }
                                label="Enabled"
                              />
                            </FormGroup>
                          </Grid>
                        </CardContainer>
                      </AppPaper>
                    </Grid>
                  );
                })}
            </AppsContainer>
          </AuthenticatedTemplate>
        </Grid>
      </MainContainer>
    </MainBox>
  );
}

export default ModAdminApp;
