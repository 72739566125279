import { useMsal } from "@azure/msal-react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";

import { postAddUser } from "utils/ds.js";


import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { getAppList, getSettings, postDelUser, postSettings, getAdminUsers } from "utils/ds.js";
import { callMsGraph } from "utils/MsGraphApiCall";

import asset_logo_thive from "assets/img/asset_logo_thive.png";
import validator from 'validator';




const MainBox = styled(Box)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'right',
    height: "100%",
    width: "100%",
    background: "#092755",
    alignItems: "center",

}));

const MainContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    overflow: "hidden",
    width: "100%",
    maxWidth: "1400px",
    justifyContent: "center",
    marginRight: "10px",
    alignContent: "flex-start"
}));


const BoxTopBorder = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    height: "10px",

    background: "#F2A92C",
    width: "100%"
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",

    display: "flex",
    height: "100px",
    background: "white",
    width: "100%"
}));

const AppsContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "100%",
    alignContent: "center",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    marginTop: "20px"
}));

const AppPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "100px",
    maxWidth: "1000px",
    width: "80%",
    justifyContent: "center"
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "100%",
    width: "100%",
}));

const UnsubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "90%",
    width: "100%",

}));

const EmailBox = styled(TextField)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "30px",
    width: "100%"
}));



function ModAdminUsers(props) {

    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [loginValue, setLoginValue] = useState("");

    const [adminList, setAdminList] = useState([]);
    const [inputValid, setInputValid] = useState("");
    const [appsData, setAppsData] = useState([]);

    const [activationData, setActivationData] = useState({});


    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [inProgress, graphData, instance]);

    // React.useEffect(() => {
    //     const node = loadCSS(
    //       'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
    //       // Inject before JSS
    //       document.querySelector('#font-awesome-css') || document.head.firstChild,
    //     );

    //     return () => {
    //       node.parentNode!.removeChild(node);
    //     };
    //   }, []);

    const updateData = () => {
        if (graphData !== null) {
            getAppList().then(response => {
                setAppsData(response)

                getAdminUsers().then(e => {

                    setAdminList(e.map((e) => e.email));
                }).catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginRequest,
                            account: instance.getActiveAccount()
                        });
                    }
                });

            });
        }
    }

    useEffect(() => {
        console.assert(adminList);
    }, [adminList]);



    useEffect(() => {
        updateData();
    }, [graphData]);



    const updateAdminList = () => {
        getAdminUsers().then(e => {
            console.assert(e);
        })
    }

    const updateDbSettings = () => {
        postSettings(activationData);
    }


    const getGraphData = (elem) => {
        return (graphData !== null && graphData !== undefined) ? graphData[elem] : "undefined"
    }

    const isActivated = (appId) => {
        return !(activationData[appId] === undefined)
    }

    const sleep = ms => new Promise(r => setTimeout(r, ms));
    const handleActivation = (appId, appName) => {
        let copyData = activationData;
        if (copyData[appId] === undefined) {
            copyData[appId] = { id: appId, name: appName }
            setActivationData({ ...copyData });
            updateDbSettings();
        }
        else {
            delete copyData[appId];
            setActivationData({ ...copyData });

            updateDbSettings();
        }
    }

    const handleLoginInputChange = (e) => {
        setInputValid(validator.isEmail(e.target.value));
        setLoginValue(e.target.value)
    }

    const handleAddAdmin = (e) => {
        postAddUser(e).then(e =>
            {
                setLoginValue();
                updateData()
            });
    }

    const handleDelAdmin = (e) => {
        postDelUser(e.e).then(e =>
            {
                setLoginValue();
                updateData()
            });

    }
    //postAddUser

    return (
        <MainBox id={"box_mainBox"}>
            <BoxTopBorder id={"box_topborder"} />
            <BoxTHiveLogo id={"box_thive_logo"}>
                <img src={asset_logo_thive} alt="T-HIVE Logo" />
            </BoxTHiveLogo>
            <MainContainer id={"box_main_container"} container spacing={1} sx={{ marginTop: "24px", height: "70%", alignContent: "center", background: '#f3f3f3' }} >
                <Grid item xs={12} sx={{ height: "80%", paddingBot: "10px", }}>
                    <UnauthenticatedTemplate>
                        <Typography variant="h6">
                            <center>Not Logged In 2</center>
                        </Typography>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <AppsContainer container direction="row"
                            alignItems="center"
                            justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="titles">
                                    <center>Manage Administrators</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <EmailBox variant="filled" value={loginValue} onChange={(e) => handleLoginInputChange(e)} inputProps={{ min: 0, style: { textAlign: 'center' } }} />
                            </Grid>
                            <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-start", marginTop: "30px" }}>
                                <div onClick={() => handleAddAdmin(loginValue)}>
                                    <Icon fontSize='large' color="primary">add_circle</Icon>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {adminList.map(e => <Chip label={e} onDelete={() => handleDelAdmin({ e })} />)}
                            </Grid>
                        </AppsContainer>
                    </AuthenticatedTemplate>
                </Grid>

            </MainContainer>
        </MainBox>
    );
}

export default ModAdminUsers;