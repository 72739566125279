import { loginRequest, graphConfig } from "../authConfig";
import { msalInstance } from "../App";

export async function callMsGraph(accessToken) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...{
                scopes: ["User.Read"],
                prompt : "login"
            },
            account: account
        });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => 
            {
               return  response.json()
            })
        .catch(error => 
            {
                return console.log(error)
            });
}
