import * as React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Paper, Stack, Typography } from "@mui/material";
import asset_logo_thive from "assets/img/asset_logo_thive.png";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#2537F3",
    },
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      marginTop="2rem"
      marginBottom="1rem"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://t-hive.io/">
        T-Hive
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function CenteredBox({ children }) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#F8F8FC"
      >
        <Box maxWidth="xm" justifySelf="center" marginX="1rem">
          <Paper elevation={3}>
            <Stack direction="column">
              <img
                src={asset_logo_thive}
                style={{
                  height: "50px",
                  objectFit: "contain",
                  width: "auto",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
                alt="T-Hive Logo"
              />
              {children}
              <Copyright />
            </Stack>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
