// import { Switch, useHistory } from "react-router-dom";
import { Route, Routes, useNavigate } from 'react-router';
import { CustomNavigationClient } from 'utils/NavigationClient.js';
import './custom.css';
import routes from "routes.js";


function App({ pca }) {
  const history = useNavigate();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <Routes>
      {routes.map((e) => {
        const Component = e.component;
        return <Route key={e.path} path={e.path} element={<Component />} />
      })
      }
    </Routes>

  )
}

export default App;