import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import _ from 'lodash';

import { loginRequest } from "authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { callMsGraph } from "utils/MsGraphApiCall";
import { getAppList, getMembership, askMembership, reovkeMembership, postSettings, getSettings } from "utils/ds.js";


import axios from 'axios';




function FragmentAdminName(props) {

    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);


    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [inProgress, graphData, instance]);


    useEffect(() => {
        console.assert("aaa");
    }, [graphData]);


    const getGraphData = (elem) => {
        return (graphData !== null && graphData !== undefined) ? graphData[elem] : "undefined"
    }



    return (
        <Typography variant="h5" sx={{paddingLeft:"10px"}}>
          {(graphData !== null) ? graphData.displayName : "NA"}
        </Typography>

    );
}

export default FragmentAdminName;