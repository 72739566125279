import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import _ from 'lodash';

import { loginRequest } from "authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { callMsGraph } from "utils/MsGraphApiCall";
import { getAppList, getMembership, askMembership, reovkeMembership, getSettings, getStatus } from "utils/ds.js";
import { useDispatch, useSelector } from "react-redux";
import { rdxSetAdminFlag } from 'redux/rdx';

import FragmentHeader from "components/fragments/FragmentHeader"

import { rdxSetLoggedUser, rdxSetLoadingState } from "redux/rdx"


// import axios from 'axios';
import asset_logo_thive from "assets/img/asset_logo_thive.png";



const MainBox = styled(Box)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'right',
    height: "100%",
    width: "100%",
    background: "#092755",
    alignItems: "center",

}));

const MainContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    overflow: "hidden",
    width: "100%",
    maxWidth: "1400px",
    justifyContent: "center",
    marginRight: "10px"
}));


const BoxTopBorder = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    height: "10px",

    background: "#F2A92C",
    width: "100%"
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
    flexShrink: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",

    display: "flex",
    height: "100px",
    background: "white",
    width: "100%"
}));

const CardContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    width: "100%",
    justifyContent: "left",
    flexDirection: "row"
}));


const AppsContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "90%",
    alignContent: "flex-start",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    overflow: "auto",
    marginTop: "20px",
    marginLeft:"0px"
}));

const AppPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    textAlign: 'center',
    height: "100px",
    maxWidth: "1000px",
    width: "90%",
    justifyContent: "center"
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "100%",
    width: "100%",
}));

const UnsubscribeButton = styled(Button)(({ theme }) => ({
    marginTop: "8px",
    height: "90%",
    width: "100%",

}));



function ModAppRequest(props) {

    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [appsData, setAppsData] = useState([]);
    const [appsDataFiltered, setAppsDataFiltered] = useState([]);
    const [configData, setConfigData] = useState(undefined);
    const [mebershipData, setMebershipData] = useState([]);
    const [membershipFlatData, setMembershipFlatData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [inProgress, graphData, instance]);

    const updateData = () => {
        if (graphData !== null) {

            var userName = getGraphData("displayName");
            dispatch(rdxSetLoggedUser(userName));

            getAppList().then(response => {
                setAppsData(response)
                dispatch(rdxSetLoadingState(false));
                getSettings().then(e => {
                    setConfigData(JSON.parse(e.config));

                    //rdxSetAdminFlag
                    getStatus().then(e => {
                        try {
                            dispatch(rdxSetAdminFlag(e.adminStatus === "True"));
                        }
                        catch {
                            dispatch(rdxSetAdminFlag(false));
                        }
                    });

                }).catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginRequest,
                            account: instance.getActiveAccount()
                        });
                    }
                    dispatch(rdxSetLoadingState(false));
                });
              

            }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
                dispatch(rdxSetLoadingState(false));
            });

        }
    }

    useEffect(() => {
        updateData();
    }, [graphData]);


    useEffect(() => {
        if (configData !== undefined) {
            var ret = appsData.filter((e) => {
                if (configData !== undefined) {
                    return configData[e.appId] !== undefined;
                }

            });
            setAppsDataFiltered(ret);


        }

    }, [configData]);



    useEffect(() => {
        if (mebershipData !== undefined) {

            let newmbflat = [];
            for (let i = 0; i < mebershipData.length; i++) {
                const element = mebershipData[i];
                newmbflat.push(element.resourceDisplayName);
            }
            setMembershipFlatData(newmbflat);
        }
    }, [mebershipData]);

    useEffect(() => {
        if (appsData !== null) {
            getMembership().then(response => {
                setMebershipData(response)
            }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [appsData]);


    // 

    const getGraphData = (elem) => {
        return (graphData !== null && graphData !== undefined) ? graphData[elem] : "undefined"
    }

    const isSubscribed = (e) => {
        return _.includes(membershipFlatData, e);
    }

    const sleep = ms => new Promise(r => setTimeout(r, ms));
    const handleSubscription = (e, subscribe, label) => {
        dispatch(rdxSetLoadingState(true)).then(() =>
        {

        
        if (subscribe)
            askMembership(e, label).then(response => { sleep(0).then(() => updateData()) });
        else
            reovkeMembership(e, label).then(response => { sleep(0).then(() => updateData()) });

        });

    }


    return (
        <MainBox id={"box_mainBox"}>
            <FragmentHeader />
            <MainContainer id={"box_main_container"} container spacing={1} sx={{ marginTop: "24px", height: "80%", alignContent: "flex-start", background: '#f3f3f3', paddingBot: "10px" }} >
                <Grid item xs={12} sx={{ height: "80%", paddingBot: "10px", }}>
                    <UnauthenticatedTemplate>
                        <Typography variant="h4">
                            <center>Not Logged In</center>
                        </Typography>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <Typography variant="titles">
                            <center>App request</center>
                        </Typography>
                        <AppsContainer id={"box_app_container"} container direction="row"
                            alignItems="flex-start"
                            justifyContent="flex-start" spacing={2}>
                            {
                                (appsDataFiltered !== undefined) && appsDataFiltered.map((e) => {
                                    return <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        <AppPaper e={e} style={{paddingTop:"10px"}} >
                                            <CardContainer container spacing={1} sx={{ height: "100%", alignContent: "center" }} >
                                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Typography variant="appInfo" sx={{ paddingLeft: "10px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>

                                                        {e.displayName || "undefined"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: "flex",  alignItems: "center", justifyContent: "center" }}>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Switch checked={isSubscribed(e.displayName)} color={ "switch"} onClick={() => handleSubscription(e.displayName, !isSubscribed(e.displayName), e.displayName)} label="Enabled" />}></FormControlLabel>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: "flex",  alignItems: "center", justifyContent: "center" }}>
                                                    {isSubscribed(e.displayName) ?
                                                        <Typography variant="appInfo" style={{color:"#2ca13e"}}>
                                                            Enabled
                                                        </Typography>
                                                        :
                                                        <Typography variant="appInfo" >
                                                            Click here to enable
                                                        </Typography>
                                                    }

                                                </Grid>



                                            </CardContainer>

                                        </AppPaper>
                                    </Grid>
                                })
                            }
                        </AppsContainer>
                    </AuthenticatedTemplate>
                </Grid>

            </MainContainer>
        </MainBox>
    );
}

export default ModAppRequest;
