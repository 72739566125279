/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import AttributionIcon from '@material-ui/icons/Attribution';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ModLanding from "components/ModLanding";
import ModAdminApp from "components/ModAdminApp";
import ModAppRequest from "components/ModAppRequest";
import ModAdminUsers from "components/ModAdminUsers";
import ModLogs from 'components/ModLogs';
import { ModWelcome } from "components/ModWelcome";

const routes = [
    {
        path: "/",
        name: "Home",
        icon: DashboardIcon,
        component: ModLanding,
        layout: "/",
        hidden:true
    },
    {
        path: "/welcome",
        name: "Welcome",
        icon: DashboardIcon,
        component: ModWelcome,
        layout: "/",
        hidden:false
    },
    {
        path: "/appreq",
        name: "My Applications",
        icon: DashboardIcon,
        component: ModAppRequest,
        layout: "/",
        hidden:false
    },
    {
        path: "/restricted/apps",
        name: "Manage Apps",
        icon: DashboardIcon,
        component: ModAdminApp  ,
        layout: "/restricted/apps",
    },
    {
        path: "/restricted/admins",
        name: "Manage Admins",
        icon: DashboardIcon,
        component: ModAdminUsers  ,
        layout: "/restricted/admins",
    },
    {
        path: "/restricted/logs",
        name: "Display Logs",
        icon: DashboardIcon,
        component: ModLogs  ,
        layout: "/restricted/logs",
    },
];

export default routes;
