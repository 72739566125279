import * as React from 'react';
import FragmentHeader from "./fragments/FragmentHeader";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {useEffect, useState} from "react";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {callMsGraph} from "../utils/MsGraphApiCall";
import {loginRequest} from "../authConfig";
import {useMsal} from "@azure/msal-react";
import {rdxSetLoggedUser} from "../redux/rdx";
import {useDispatch} from "react-redux";

const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'center',
    height: "100%",
    width: "100%",
    background: "#092755",
}));

const MainContainer = styled(Grid)(() => ({
    display: "flex",
    overflow: "hidden",
    width: "100%",
    maxWidth: "1400px",
    marginRight: "10px",
    flexDirection: "column",
    alignItems: "center"
}));

export const ModWelcome = () => {
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [inProgress, graphData, instance]);

    useEffect(() => {
        if (graphData !== null) {
            dispatch(rdxSetLoggedUser(getGraphData("displayName")));
        }
    }, [graphData]);

    const getGraphData = (elem) => {
        return (graphData !== null && graphData !== undefined) ? graphData[elem] : "undefined"
    }

    return (
        <MainBox id={"box_mainBox"}>
            <FragmentHeader />
            <MainContainer id={"box_main_container"} container spacing={1} sx={{ marginTop: "24px", height: "80%", background: '#f3f3f3', paddingBot: "10px" }} >
                <Typography variant="h1"
                            fontSize="26px"
                            fontWeight={600}
                            marginTop="36px"
                            marginBottom="16px"
                            width="70%"
                >
                    Welcome to T-Hive Single Sign-On registration home page
                </Typography>
                <Typography fontSize="20px" width="50%">
                    You have successfully registered on the T-Hive portal. Please click on the link below to access T-Hive Partner Portal.
                </Typography>
                <Link href={window.globalConfig.REACT_PORTAL_URL} fontSize="20px" target="_blank">Portal</Link>
            </MainContainer>
        </MainBox>
    )
}
