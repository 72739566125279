import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { loginRequest } from "authConfig";
import { useState, useEffect } from "react";
import { Navigate } from "react-router";
import { postGetInvitation, postInvitation } from "utils/ds.js";
import validator from "validator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { rdxSetLoadingState } from "redux/rdx";
import { Stack, TextField, Typography, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import CenteredBox from "./CenteredBox";

function ModLanding(props) {
  const { instance, inProgress } = useMsal();
  const [loginValue, setLoginValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [redeemURL, setRedeemURL] = useState(undefined);
  const [inputValid, setInputValid] = useState(false);
  const [modeInvitation, setModeInvitation] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogin = (loginType) => {
    if (inputValid) {
      dispatch(rdxSetLoadingState(true)).then(() =>
        postInvitation(loginValue)
          .then(() => {
            loginRequest.loginHint = loginValue;
            if (loginType === "popup") {
              instance
                .loginPopup(loginRequest)
                .catch((e) => console.log("Error doing loginPopup:" + e));
            } else if (loginType === "redirect") {
              instance
                .loginRedirect(loginRequest)
                .catch((e) => console.log("Error doing loginRedirect:" + e));
            }
            dispatch(rdxSetLoadingState(false));
          })
          .catch(() => {
            console.log("email not found");
            dispatch(rdxSetLoadingState(false));
            setModeInvitation(true);
          })
      );
    }
  };

  const handleInvitation = () => {
    if (inputValid) {
      dispatch(rdxSetLoadingState(true)).then(() =>
        postGetInvitation({
          emailAddress: loginValue,
          firstName: firstName,
          lastName: lastName,
        })
          .then((ret) => {
            dispatch(rdxSetLoadingState(false));
            window.location.href = ret.redirectURL;
            setRedeemURL(ret.redirectURL);
          })
          .catch(() => {
            dispatch(rdxSetLoadingState(false));
            setDialogOpen(true);
            console.log("invitation cannot be sent");
          })
      );
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLoginInputChange = (e) => {
    setInputValid(validator.isEmail(e.target.value));
    setLoginValue(e.target.value);
  };

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params.has("email")) {
      navigate("/welcome");
    }
  }, []);

  const defaultTheme = createTheme();

  if (redeemURL !== undefined) return <Navigate to={redeemURL} />;

  return (
    <ThemeProvider theme={defaultTheme}>
      {modeInvitation === false ? (
        <>
          <UnauthenticatedTemplate>
            <CenteredBox>
              <Stack
                flexDirection="column"
                alignItems="center"
                spacing={2}
                px={{ xs: "1rem", sm: 10 }}
              >
                <Typography component="h1" variant="h5" marginBottom={2}>
                  Register with your TICO e-mail address
                </Typography>
                <TextField
                  variant="outlined"
                  label="E-mail address"
                  value={loginValue}
                  onChange={(e) => {
                    handleLoginInputChange(e);
                    e.preventDefault();
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin("redirect");
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={() => handleLogin("redirect")}
                  fullWidth
                >
                  Register
                </Button>
              </Stack>
            </CenteredBox>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Navigate to="/welcome" />
          </AuthenticatedTemplate>
        </>
      ) : (
        <>
          <UnauthenticatedTemplate>
            <CenteredBox>
              <Stack
                flexDirection="column"
                alignItems="center"
                spacing={4}
                px={{ xs: "1rem", sm: 10 }}
              >
                <Typography component="h1" variant="h5">
                  Please tell us your name
                </Typography>
                <Typography>
                  Complete this form to receive an invitation to access Portal.
                </Typography>
                <TextField
                  variant="outlined"
                  label="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="This is your TICO e-mail address"
                  value={loginValue}
                  disabled={true}
                  fullWidth
                />
                <Button variant="contained" onClick={() => handleInvitation()}>
                  Create account
                </Button>
              </Stack>
            </CenteredBox>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Navigate to="/welcome" />
          </AuthenticatedTemplate>

          <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle id="alert-dialog-title">
              {"Partner domain not found"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                It seems your email address does not belong to the T-Hive
                Business Partners list
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} autoFocus>
                Go Back
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </ThemeProvider>
  );
}

export default ModLanding;
