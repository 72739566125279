import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import asset_logo_thive from "assets/img/asset_logo_thive.png";

const BoxTopBorder = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  height: "10px",

  background: "#F2A92C",
  width: "100%",
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",

  display: "flex",
  height: "100px",
  background: "white",
  width: "100%",
}));

const HeadBox = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "right",

  width: "100%",
  background: "white",
  alignItems: "flex-start",
}));

function FragmentHeader(props) {
  // const [graphData, setGraphData] = useState(null);
  const comUserLogged = useSelector((state) => state.commons.comUserLogged);

  useEffect(() => {}, []);

  return (
    <HeadBox>
      <BoxTopBorder id={"box_topborder"} />
      <BoxTHiveLogo id={"box_thive_logo"}>
        <img
          src={asset_logo_thive}
          ex={{ position: "relative", maxHeight: "50px" }}
          alt="T-HIVE Logo"
        />
        <Typography style={{ position: "absolute", right: "2rem" }}>
          {comUserLogged}
        </Typography>
      </BoxTHiveLogo>
    </HeadBox>
  );
}

export default FragmentHeader;
