



//AUTH STATUS ACTION
const COM_ADMIN_FLAG = 'thive/COM_ADMIN_FLAG'
const COM_LOADING_STATE = 'thive/COM_LOADING_STATE'
const COM_USER_LOGGED = 'thive/COM_USER_LOGGED'

export const initialState = {
    comAdminFlag: false,
    comLoadingState: false,
    comUserLogged: "Not Logged in"
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {

        case 'thive/COM_ADMIN_FLAG':
            return Object.assign({}, state, {
                comAdminFlag: action.payload,
            })
            break;

        case 'thive/COM_LOADING_STATE':
            return Object.assign({}, state, {
                comLoadingState: action.payload,
            })
            break;

            case 'thive/COM_USER_LOGGED':
                return Object.assign({}, state, {
                    comUserLogged: action.payload,
                })
                break;

        default:
            return state;
    }
}


//
export function rdxSetAdminFlag(value) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: COM_ADMIN_FLAG, payload: value });
            resolve();
        })
    };
}


export function rdxSetLoadingState(value) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: COM_LOADING_STATE, payload: value });
            resolve();
        })
    };
}


export function rdxSetLoggedUser(value) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: COM_USER_LOGGED, payload: value });
            resolve();
        })
    };
}