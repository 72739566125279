// import { Switch, useHistory } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";
import './custom.css';


import { HashRouter } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch  } from "react-redux";
import { NavLink } from "react-router-dom";
import { msalConfig } from "./authConfig";

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import asset_admin_logo from "assets/img/asset_admin_logo.png";

import AppRouter from "AppRouter";
import FragmentAdminName from "components/fragments/FragmentAdminName";

import routes from "routes.js";
import { rdxSetLoadingState } from "redux/rdx"


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const drawerWidth = 240;

var forceUserView = false;


const BoxAdminLogo = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  justifyContent: "flex-start",
  alignContent: "center",
  alignItems: "center",
  paddingLeft: "10px",
  display: "flex",
  height: "100px",
  background: "transparent",
  width: "100%",
  marginTop: "10px"
}));

const BoxTopBorder = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  height: "10px",

  background: "#F2A92C",
  width: "100%"
}));

const BoxTHiveLogo = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  display: "flex",
  height: "100px",
  background: "white",
  width: "100%"
}));



const DrawerList = styled(List)(({ theme }) => ({
  color: "white"
}));


export const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});


const theme = createTheme({
  typography: {
    fontFamily: [
      "montserrat, sans-serif",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
  },
});

theme.typography.h4 = {
  fontSize: '48px',
  fontWeight: "800",
  fontStyle: "normal"

};

theme.typography.h6 = {
  fontSize: '21px',
  fontWeight: "600",
  fontStyle: "normal"
};
theme.typography.appInfo = {
  fontSize: '16px',
  fontWeight: "600",
  fontStyle: "normal"
};

theme.typography.h5 = {
  fontSize: '21px',
  fontWeight: "600",
  fontStyle: "normal",
  color: "white"

};

theme.typography.titles = {
  fontSize: '36px',
  fontWeight: "600",
  fontStyle: "normal",
};

theme.typography.subscriptions = {
  fontSize: '36px',
  fontWeight: "600",
  fontStyle: "normal",
};

theme.palette.switch = {
  main: '#092755'
}

function App() {
  const comAdminFlag = useSelector(state => state.commons.comAdminFlag);

  const open= useSelector(state => state.commons.comLoadingState);
  const dispatch = useDispatch();

  const handleClose = () => {
    // setOpen(false);
  };
  const handleToggle = () => {
    // setOpen(!open);
  };


  useEffect(() => {
    console.assert(comAdminFlag);
  }, [comAdminFlag]);




  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HashRouter basename={baseUrl}>
        {
          (comAdminFlag && !forceUserView) ?

            <Box sx={{ display: 'flex', alignItems: "flex-start", height: "100%" }}>

              <CssBaseline />

              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    background: "#092755",
                    border: "none"
                  },

                }}
                variant="permanent"
                anchor="left"

              >
                <BoxTopBorder id={"box_topborder"} />
                <BoxTHiveLogo id={"box_thive_logo"} />

                <BoxAdminLogo id={"box_thive_logo"}>
                  <img src={asset_admin_logo} alt="T-HIVE Admin" />
                </BoxAdminLogo>

                <FragmentAdminName />

                <DrawerList>

                  {routes.map((e, index) => (!e.hidden) ? (
                    <ListItem button key={e.path} component={NavLink} to={e.path} >
                      <ListItemIcon sx={{ color: "white" }}>
                        <e.icon />
                      </ListItemIcon>
                      <ListItemText primary={e.name} />
                    </ListItem>
                  ) : <div />)}
                </DrawerList>
                <Divider />
              </Drawer>
              <Box id={"main_box"}
                component="main"
                sx={{ flexGrow: 1, padding: 0, bgcolor: 'background.default', alignItems: "flex-start", height: "100%" }}
              >
                <MsalProvider instance={msalInstance}>
                  <AppRouter pca={msalInstance} />
                </MsalProvider>
              </Box>
            </Box>
            :
            <MsalProvider instance={msalInstance}>
              <AppRouter pca={msalInstance} />
            </MsalProvider>
        }
      </HashRouter >
    </ThemeProvider >
  )
}

export default App;