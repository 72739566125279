import { loginRequest } from "../authConfig";
import { msalInstance } from "../App";
import axios from "axios";

export async function getAppList(accessToken) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/applist', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function getStatus(accessToken) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/admin/status', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}

export async function getMembership(accessToken) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/membership', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}

export async function askMembership(e, label) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;


    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/askmembership/grant/' + e + "/" + label, config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function reovkeMembership(e, label) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;


    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/askmembership/revoke/'  + e + "/" + label, config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}

export async function postSettings(settings) {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` }, data: settings }
    const bodyParameters = {
        key: "value"
    };

    return axios.post('/admin/settings', config.data, config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}

export async function getSettings(settings) {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/admin/settings', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function postInvitation(payload) {
    return new Promise(
        async function (resolve, reject) {           
      
            const config = { data: {emailAddress : payload} }
         
            return axios.post('/invitation', config.data)
                .then(response => {
                    resolve( )
                })
                .catch(error => {
                    reject( )
                });
        }
    );
}


export async function postGetInvitation(payload) {
    return new Promise(
        async function (resolve, reject) {
            var accessToken = null;
          
            const config = { data: payload }
            const bodyParameters = {
                key: "value"
            };

            return axios.post('/invitation/get', config.data)
                .then(response => {
                    resolve( response.data)
                })
                .catch(error => {
                    reject( )
                });
        }
    );
}


export async function postAddUser(email) {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` }, data: {email: email} }
    const bodyParameters = {
        key: "value"
    };

    return axios.post('/admin/add', config.data, config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function postDelUser(email) {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` }, data: {email: email} }
    const bodyParameters = {
        key: "value"
    };

    return axios.post('/admin/del', config.data, config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function getAdminUsers() {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/admin/list', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}


export async function getLogs() {
    var accessToken = null;
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const config = { headers: { 'Authorization': `Bearer ${accessToken}` } }
    const bodyParameters = {
        key: "value"
    };

    return axios.get('/admin/logs', config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return console.log(error)
        });
}
